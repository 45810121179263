import { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAssignRules } from 'store/slices/assignRules';
import { fetchUsers } from 'store/slices/users';
import { useTranslation } from 'react-i18next';
import {
  FlexWrapper,
  AccentButton,
  Modal,
  Overflow,
  Block,
  Page,
  Loader,
  SearchForm,
  Result,
  TitlePage
} from 'base/styled';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import AutoAssignForm from './AutoAssignForm';
import AutoAssignTable from './AutoAssignTable';
import Responsive from 'context/responsive';
import network from 'base/network';
import Pagination from 'base/components/Pagination';

const Form = styled(Block)`
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 99vw;
  }
`;

const Flex = styled(FlexWrapper)`
  padding: 30px 0 10px;
  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

export default function Index() {
  const { t: rootT } = useTranslation();
  const { t: autoT } = useTranslation('autoassign');
  const dispatch = useDispatch();
  const [show, setShow] = useState();
  const [success, setSuccess] = useState();
  const { assignRules, status, total_pages } = useSelector(
    (state) => state.assignRulesReducer
  );
  const { users } = useSelector((state) => state.usersReducer);

  const [ setUserList] = useState([]);
  const [search, setSearch] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [text, setText] = useState();
  const [gic, setGic] = useState([]);
  const [naics, setNaics] = useState([]);
  const [sentSearch, setSentSearch] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  function handleText(e) {
    setText(e.target.value);
    setSearchResults(assignRules.filter((el) => {
      return el?.name?.toLowerCase().includes(text?.toLowerCase());
    }));
    setSearch(true);
  }

  function AddRuleHandler(data) {
    setUserList((prevList) => {
      return [
        ...prevList,
        {
          name: data.name,
          website: data.website,
          user: data.user,
          filtersType: data.filterType,
          equals: data.equals,
          filtersValue: data.value,
        },
      ];
    });
  }

  function showHandler() {
    setShow(true);
  }

  function setShowOff() {
    setShow();
    setSuccess();
  }

  const ctx = useContext(Responsive);

  useEffect(() => {
    dispatch(fetchUsers({users}));
    setSearchResults(assignRules);
    /* eslint-disable */
  }, [dispatch]);

  useEffect(() => {
    if(!sentSearch) {
      dispatch(fetchAllAssignRules({ assignRules, per_page: 100, page: pageNumber }));
    }
    setSearchResults(assignRules);
    /* eslint-disable */
  }, [dispatch]);
  /* eslint-enable */
  
  useEffect(() => {
    setSearchResults(assignRules);
    /* eslint-disable */
  }, [search]);
  /* eslint-enable */

  const getIndGic = async () => {
    let res = await network
      .get('/api/industries/gic', { params: {} })
      .then(({ data }) => data)
      .catch((error) => console.log(error));

    setGic(res.results?.map((d) => {
      return {
        value: d[0],
        label: d[1]
      };
    }));
  };

  useEffect(() => {
    const getInd = async () => {
      let res = await network
        .get('/api/industries/naics', { params: {} })
        .then(({ data }) => data)
        .catch((error) => console.log(error));
      setNaics(res.results?.map(d => {
        return {
          label: d.value,
          value: d.code,
          options: d.subindustries?.map((e) => {
            return {
              label: Object.values(e).toString(),
              value: Object.keys(e).toString(),
            };
          }),
        };
      }
      ));
    };
    getInd();
    getIndGic();
  }, []);

  function handleSearchSubmit(e) {
    e.preventDefault();
    setSentSearch(true);
    setText(text);
    dispatch(fetchAllAssignRules({ query: text, per_page: 100 }));
  }

  function handleSearchClick() {
    setSearchResults(assignRules.filter((el) => {
      return el.name.toLowerCase().includes(text.toLowerCase());
    }));
    setSearch(true);
  }

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [success]);

  function handlePageNext() {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  }

  function handlePagePrev() {
    setPageNumber(prevPageNumber => prevPageNumber - 1);
  }

  useEffect(() => {
    if (pageNumber >= 1) {
      dispatch(fetchAllAssignRules({ assignRules,  query: '', per_page: 100, page: pageNumber  }));
    }
    /* eslint-disable */
  }, [pageNumber, dispatch]);
    /* eslint-enable */

  return (
    <Page>
      {status === 'loading' ? (
        <Loader />
      ) : (
        <>
          <Flex>
            <TitlePage>{autoT('rule')}</TitlePage>
            {!ctx.isMobile && (
              <SearchForm onSubmit={handleSearchSubmit}>
                <SearchIcon onClick={handleSearchClick} />
                <input className='light' type="search" onChange={handleText} value={text}/>
              </SearchForm>
            )}
            <AccentButton onClick={showHandler}>
              {rootT('addNewRule')}
            </AccentButton>
          </Flex>

          {show && (
            <>
              <Modal>
                <Overflow onClick={setShowOff}></Overflow>
                {!success && (
                  <Form>
                    <AutoAssignForm
                      onAddRule={AddRuleHandler}
                      setShowOff={setShowOff}
                      setSuccess={setSuccess}
                      users={users}
                      gic={gic} 
                      naics={naics}
                      setShow={setShow}
                    />
                  </Form>
                )}
              </Modal>
            </>
          )}
          {success && <Result>{rootT('Rule was saved!')}</Result>}
          <AutoAssignTable list={text ? searchResults : assignRules.slice().sort((a, b) => {
            return b?.updated_at?.localeCompare(a.updated_at);
          })} users={users} gic={gic} naics={naics} setShowMain={setShow}/>
          {Pagination(total_pages, pageNumber, handlePageNext, handlePagePrev, setPageNumber)}
        </>
      )}
    </Page>
  );
}
