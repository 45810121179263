import network from 'base/network/index';

export const getDashboardQueues = ( {id, per_page, page}) => {
  return network.get('/api/dashboard_queues', {
    params: {
      ga_view_id: id,
      page: page,
      per_page: per_page
    }
  });
};

export const getDashboardQueuesDropdown = ( {id, per_page, page}) => {
  return network.get('/api/dashboard_queues', {
    params: {
      ga_view_id: id,
      page: page,
      per_page: per_page,
      for_dropdown: true
    }
  });
};

export const postQueue = (body) => {
  return network.post('/api/dashboard_queues', body);
};

export const putQueue = (id, body) => {
  return network.put(`/api/dashboard_queues/${id}`, body);
};

export const deleteQueue = (id) => {
  return network.delete(`/api/dashboard_queues/${id}`);
};