import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import styled from 'styled-components';
import FormInvite from 'pages/Users/FormInvite';
import { Modal, Overflow, Block } from "base/styled";
import { ReactComponent as Close } from 'assets/images/close.svg';
import img from 'assets/images/invite.svg';


const Box = styled(Block)`
  background: #fff url(${img}) no-repeat center bottom/ cover;
  padding: 20px 30px 70px 50px; 
  margin: 0 auto;
  width: 635px;
  height: 95%;
  max-height: 628px;
  overflow-y: scroll;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
  }
`;

const Card = styled.div`
  width: 100%;
`;

const Content = styled.div`
  padding-left: 60px
  h2 {
    font-size: 44px;
    color: #FF8441;
    font-weight: 800;
    max-width: 350px
    margin: 0 0
  }
  p {
    max-width: 370px;
    font-size: 14px;
    line-height: 160%;
    margin-top: 0;
    margin-bottom: 5px;
  }
  @media (max-width: 768px) {
    padding-left: 10px
  }
`;

export default function InvitationCard({handleInvitationOff, setSuccess}) {
  const { t: onboardingT } = useTranslation('onboarding');
  const [invite, setInvite] = useState(false);

  useEffect(() => {
    setSuccess(false);
    setInvite(true);
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  return (
    <Modal className='over'>
      <Overflow></Overflow>
      <Box>
        <Card>
          <Content>
            <h2>{onboardingT('invite')}</h2>
            <p>{onboardingT('inviteContent')}</p>
          </Content>
          <FormInvite
            invite={invite}
            setInvite={setInvite}
            setSuccess={setSuccess}
            edit={false}
            user={null}
          />
        </Card>       
        <Close onClick={handleInvitationOff}/>
      </Box>
    </Modal>
   
  );
}

InvitationCard.propTypes = {
  handleInvitationOff: func,
  setSuccess: func
};
