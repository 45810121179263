import { useTranslation } from 'react-i18next';
import { func, any, bool, array } from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Close } from 'assets/images/close.svg';
import {
  FlexWrapper,
  TitleTwo
} from 'base/styled';
import FormInvite from './FormInvite';

const Popup = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: 530px;
  max-height: 550px;
  overflow-y: scroll;
  button {
    height: 37px;
    margin-right: 10px;
  }
  .css-16pkpmb-SelectContainer {
    position: relative;
  }
`;

const CloseBtn = styled.div`
  margin: auto 0;
`;

export default function FormUser({
  handleShowOff,
  setSuccess,
  setShow,
  edit,
  user,
  usersPage,
  allWebsites
}) {
  const { t: usersT } = useTranslation('users');

  return (
    <Popup>
      <FlexWrapper>
        <TitleTwo>{edit ? usersT('edit') : usersT('invite')}</TitleTwo>
        <CloseBtn onClick={handleShowOff}>
          <Close />
        </CloseBtn>
      </FlexWrapper>
      <FormInvite 
        handleShowOff={handleShowOff}
        setSuccess={setSuccess}
        setShow={setShow}
        edit={edit}
        user={user}
        usersPage={usersPage}
        allWebsites={allWebsites}
      />
    </Popup>
  );
}

FormUser.propTypes = {
  handleShowOff: func,
  setSuccess: func,
  setShow: func,
  edit: bool,
  user: any,
  allWebsites: array,
  usersPage: bool
};
