import { useContext, useEffect, useState } from "react";
import parse from 'html-react-parser';
import { useSelector } from "react-redux";
import {
  Block,
  FlexCenter,
  FlexWrapper, GhostButton, Modal, Overflow,
  Result, StyledSelect, TextBlackSmall, TextBlackSmallThin, TitleBlack
} from "base/styled";
import { any, bool, func, number } from "prop-types";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from 'assets/images/angle_left.svg';
import { ReactComponent as SendIcon } from 'assets/images/sendBlack.svg';
import { ReactComponent as Copy } from 'assets/images/copy.svg';
import front from 'assets/images/frontL.png';
import ng from 'assets/images/nginL.png';
import styled from "styled-components";
import Responsive from "context/responsive";
import makeAsOptionsSet from "base/utils/makeAsOptionsSet";
import network from "base/network";
import FormEmail from "./FormEmail";

const Back = styled.a`
  position: absolute;
  top: 50px;
  left: 0;
  display: flex;
  text-decoration: none;
  p {
    margin: 0 0 5px 10px;
  }
  svg {
    margin: 2px 5px 0 0
  }
  @media (max-width: 768px) {
    top: 0;
    left: 10px
  }
`;

const Steps = styled.div`
  margin: 30px auto;
  padding: 0 60px 0 0;
  overflow-y: auto;
  overflow-y: visible;
  @media (min-width: 768px) {
    width: 100%
  }
  @media (min-width: 800px) {
    width: 700px
  }
  @media (min-width: 1200px) {
    width: 1100px
  }
  @media (max-width: 768px) {
    margin: 0;
    padding:30px 15px;
  }

  h1 {
    max-width: 65%;
    margin: 15px auto 40px;
  }
  .selects {
   .custom-select .custom-select__control .custom-select__indicator,
  .custom-select .custom-select__control .custom-select__indicator-separator {
      color: #2c2c2c; 
      cursor: pointer;
  }
  .custom-select .custom-select__control:hover .custom-select__indicator,
  .custom-select .custom-select__control:hover .custom-select__indicator-separator {
      color: #474747; 
  }
  .custom-select .custom-select__control:hover .custom-select__indicator-separator {
      background-color:#474747;
  }
  }
`;

const Imgs = styled.div`
  display: flex;
  justify-content: center;
  div{
    border-radius: 20px;
    width: 76px;
    height: 76px;
    margin: 10px;
    opacity: .25;
  &:nth-child(1) {
    background: url(${front}) no-repeat 10% center
  }
  &:nth-child(2) {
    background: url(${ng}) no-repeat 10% center
  }
  &.selected {
    opacity: 1;
  }
`;

const Send = styled.div`
display: flex;
cursor: pointer;
margin: 10px 0;
&.empty {
  cursor: not-allowed;
}
  p {
    margin: 0;
  }
  svg {
    margin: 0 10px 0 0
  }
`;

const Code = styled.div`
background: #e5f2f6;
border-radius: 4px;
padding: 4px 3px;
position: relative;
margin: 14px 0 10px;
`;

const Area = styled(Block)`
  display: flex;
  flex-direction: column;
  min-height: 120px
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    margin: 5px 0
  }
  pre {
    font-size: 11px;
  }
`;

const Button = styled(GhostButton)`
  border: none;
  outline: none;
  background: transparent;
  color: ${(props) => props.theme.colors.black};  
  position: absolute;
  top: -28px;
  right: 0;
`;

const Text = styled.div`
 font-size: .75rem
`;

export default function Integration({handleShowOff, website_id, typeIntegrations, header}) {

  const { t: websiteT } = useTranslation('website');

  const [selectedWebsite, setSelectedWebsite] = useState();
  const [selectedType, setSelectedType] = useState(
    typeIntegrations ? typeIntegrations.name == 'Nginx' ? {value: "nginx", label: "Nginx"} :
      {value: "front", label: "Tracking Script"} : null);
  const { ids } = useSelector((state) => state.gaViewIdReducer);
  const [content, setContent] = useState();
  const [copied, setCopied] = useState(false);
  const [formEmail, setFormEmail] = useState(false);
  const [success, setSuccess] = useState();
  const options = makeAsOptionsSet(ids, 'website_id', 'name', 'sunsetted');
  const ctx = useContext(Responsive);

  const types = {
    "front": "Tracking Script",
    "nginx": "Nginx"
  };

  const optionsType = Object.keys(types).map(key => ({
    value: key,
    label: types[key]
  }));

  useEffect(() => {
    if(ids && website_id) {
      setSelectedWebsite(options.find(e => e.value == website_id));
    }
    /* eslint-disable */
  }, [ids]);
   /* eslint-enable */

  function handleSelectChangeWebsite(payload) {
    setSelectedWebsite(payload);
  }
  function handleSelectChangeType(payload) {
    setSelectedType(payload);
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#000000',
      fontSize: '12px',
      background: 'inherit',
      borderBottom: state.isSelected ? '1px solid #EDEDED' : '0',
      '&:hover': {
        background: `#FFF9F9`,
      },
      padding: state.isSelected ? '10px 5px 10px 11px' : '10px 5px 10px 15px',
      width: ctx.isMobile ? 200 : 500,
    }),
    control: (provided) => ({
      ...provided,
      display: 'flex',
      borderRadius: '8px',
      //width: ctx.isMobile ? 180 : 300,
      paddingLeft: '10px',
      border: 'none',
      background: "#fff",
      fontWeight: '400',
      color: 'red',
      boxShadow: '0 1px 5px 0 rgba(87, 85, 85, 0.24);',
      '@media only screen and (min-width: 1300px)': {
        width: 300,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      width: ctx.isMobile ? 240 : 300,
      color: state.selectProps.menuColor,
      padding: 0,
      overflowX: 'hidden',
      marginLeft: '0',
      textAlign: 'left',
      display: 'inline-block',
      '@media only screen and (min-width: 1200px)': {
        width: 300,
      },
    }),
  };

  const TypeStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#000000',
      fontSize: '12px',
      background: 'inherit',
      borderBottom: state.isSelected ? '1px solid #EDEDED' : '0',
      '&:hover': {
        background: `#FFF9F9`,
      },
      padding: state.isSelected ? '10px 5px 10px 11px' : '10px 5px 10px 15px',
      width: ctx.isMobile ? 200 : 500,
    }),
    control: (provided) => ({
      ...provided,
      display: 'flex',
      borderRadius: '8px',
      //width: ctx.isMobile ? 180 : 300,
      paddingLeft: '10px',
      border: 'none',
      background: selectedType?.value == 'nginx' ? `linear-gradient(90deg, #f9b5a5 0%, #6093e9 100%);`: 
        selectedType?.value == 'front' ? `linear-gradient(90deg, #7bcb99 0%, #b6ebb1 100%);` : "#fff" ,
      fontWeight: '400',
      color: 'red',
      boxShadow: '0 1px 5px 0 rgba(87, 85, 85, 0.24);',
      '@media only screen and (min-width: 1300px)': {
        width: 300,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      width: ctx.isMobile ? 240 : 300,
      color: state.selectProps.menuColor,
      padding: 0,
      textAlign: 'left',
      overflowX: 'hidden',
      display: 'inline-block',
      '@media only screen and (min-width: 1200px)': {
        width: 300,
      },
    }),
  };

  const getContent = async () => {
    await network
      .get(`/api/websites/integration/${selectedWebsite?.value}`,
        {params: { id: selectedWebsite?.value, type: selectedType?.value}})
      .then(({ data }) => {
        setContent(data?.results);
        return data;
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if(selectedWebsite) {

      if (!selectedType) {
        setSelectedType({value: 'front', label: 'Tracking Script'});
      }
      getContent();
    }
    /* eslint-disable */
  }, [selectedWebsite, selectedType]);
    /* eslint-enable */

  const copyToClipboard = (idx, text) => () => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied((state) => ({
        ...state,
        [idx]: true,
      }));
    });
  };

  const RenderContentItem = ({ item, idx, onCopy, copied }) => {
    const renderContent = () => {
      switch (item.type) {
      case 'html':
        return parse(item.content.replace(/\n/g, '<br>'));
      case 'code':
        return (
          <Code>
            <Button onClick={onCopy(idx, item.content)} disabled={copied[idx]}>
              <Copy />
              {copied[idx] ? 'Copied!' : 'Copy'}
            </Button>
            <pre>{item.content}</pre>
          </Code>
        );
      case 'inline_code':
        return (
          <Code>
            <Button onClick={onCopy(idx, item.content)} disabled={copied[idx]}>
              <Copy />
              {copied[idx] ? 'Copied!' : 'Copy'}
            </Button>
            <>{item.content}</>
          </Code>
        );
      default:
        return null;
      }
    };
  
    return <Text key={idx}>{renderContent()}</Text>;
  };

  const handleFormEmail = () => () => {
    if(selectedWebsite) {
      setFormEmail(true);
    }
  };

  function handleFormEmailOff() {
    setFormEmail(false);
  }

  useEffect(() => {   
    const timerIn = setTimeout(() => {
      if(success) {
        setSuccess(false);
      }
    }, 2200);
    return () => clearTimeout(timerIn);
  }, [success]);

  return (
    <Steps>
      <Back onClick={handleShowOff}>
        <BackIcon />
        <TextBlackSmall>{header ? websiteT('leads') : websiteT('websites') }</TextBlackSmall>
      </Back>
      <Imgs>
        <div className={selectedType?.value == 'front' ? 'selected' : ''}></div>
        <div className={selectedType?.value == 'nginx' ? 'selected' : ''}></div>
      </Imgs>
      <TitleBlack>
        {websiteT('pleaseSelect')}
      </TitleBlack>
      <FlexCenter className="selects">
        <StyledSelect
          value={selectedWebsite}
          options={options}
          onChange={handleSelectChangeWebsite}
          styles={customStyles}
          className="custom-select"
          classNamePrefix="custom-select"
          components={{ IndicatorSeparator: () => null}}
        />
        <StyledSelect
          value={selectedType}
          options={optionsType}
          onChange={handleSelectChangeType}
          styles={TypeStyles}
          className="custom-select"
          classNamePrefix="custom-select"
          components={{ IndicatorSeparator: () => null}}
        />
      </FlexCenter>
      <FlexWrapper>
        <TextBlackSmallThin>{websiteT('instructions')}</TextBlackSmallThin>
        <Send className={selectedWebsite ? '' : 'empty'} onClick={handleFormEmail()}>
          <SendIcon />
          <TextBlackSmall>{websiteT('send')}</TextBlackSmall>
        </Send>    
      </FlexWrapper>
      <Area>
        {content?.map((e, idx) => (
          <div key={idx}>
            {/* {renderContent(e, idx)} */}
            <RenderContentItem item={e} idx={idx} onCopy={copyToClipboard} copied={copied} />
          </div>
        ))}
      </Area>
      {formEmail && (
        <Modal>
          <Overflow onClick={handleFormEmailOff}></Overflow>
          <FormEmail
            handleFormEmailOff={handleFormEmailOff}
            id={selectedWebsite?.value}
            setSuccess={setSuccess}
            integration={true}
          />
        </Modal>
      )}
      {success && (
        <>
          <Result>
            {success}
          </Result>
        </>
      )}
    </Steps>
  );
}

Integration.propTypes = {
  handleShowOff: func,
  header: bool,
  website_id: number,
  typeIntegrations: any,
  content: any,
  item: any,
  idx: any, onCopy:any, 
  copied: any
};